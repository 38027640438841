import { getCurrentInstance } from 'vue';
import { useRoute, type RouteLocationNormalizedLoadedGeneric } from 'vue-router';
import { showErrorMsg } from '@/common/utils/commonUtils';
import { useInternational } from '@/common/locale';
import { useRolePermissionStore } from '@/config/stores/rolePermission';
import { useViewModeStore } from '@/common/stores/view-mode';
import { storeToRefs } from 'pinia';
import { store } from '@/common/store';
import type {
  RolePermissionKeyType,
  PermissionListAPIDataValue,
} from '@/config/views/permission/rolePermission/rolePermission.type';

export interface UseRolePermission {
  type:
    | 'listOnOff'
    | 'permission'
    | 'menuOnOff'
    | 'detailSlide'
    | 'action'
    | 'move'
    | 'add'
    | 'edit'
    | 'delete';
  showError?: boolean;
  rolePermissionKey?: RolePermissionKeyType;
}

export const findPermissionByTranslateId = (
  rolePermission?: RolePermissionKeyType,
  route?: RouteLocationNormalizedLoadedGeneric,
): PermissionListAPIDataValue | undefined => {
  const { globalPermissionList } = storeToRefs(useRolePermissionStore());
  const { viewMode } = storeToRefs(useViewModeStore());

  return Object.values(globalPermissionList.value || {}).reduce<
    PermissionListAPIDataValue | undefined
  >((acc, category) => {
    if (typeof rolePermission === 'object') {
      if (rolePermission[viewMode.value] === undefined) {
        return (
          acc ||
          category.find((item) => item.translateId === route?.meta.rolePermission?.[viewMode.value])
        );
      }
      return acc || category.find((item) => item.translateId === rolePermission[viewMode.value]);
    }

    return acc || category.find((item) => item.translateId === rolePermission);
  }, undefined);
};

export const useRolePermission = () => {
  const myInfo = store.getters['myInfo/getAccountInfo'];
  const ctx = getCurrentInstance()!.appContext.config.globalProperties;
  const { t } = useInternational();
  const route = useRoute();

  const isPermissionDenied = ({
    type,
    showError = true,
    rolePermissionKey = route.meta.rolePermission,
  }: UseRolePermission) => {
    const curRolePermission = findPermissionByTranslateId(rolePermissionKey, route);

    if (
      myInfo.role !== 0 &&
      (curRolePermission?.[type] === false || curRolePermission?.[type] === null)
    ) {
      if (showError) {
        showErrorMsg(ctx, t('MESSAGE.NO_PERMISSION'));
      }
      return true;
    }

    return false;
  };

  const hasPermissionTo = ({
    type,
    rolePermissionKey = route.meta.rolePermission,
  }: UseRolePermission) => {
    const curRolePermission = findPermissionByTranslateId(rolePermissionKey, route);

    if (myInfo.role !== 0 && curRolePermission?.[type] === false) {
      return false;
    }

    return true;
  };

  return {
    isPermissionDenied,
    hasPermissionTo,
  };
};
