import { useRtmApi } from '@/common/utils/apiUtils';
import {
  getGroupAndRuleListAlertCommonControllerAxios,
  getCommonAlertListAlertCommonControllerAxios,
  getK8sAlertListAlertCommonControllerAxios,
  getClusterTopologyAlertListAlertCommonControllerAxios,
} from '@/openapi/alert/api/alert-common-controller-api';
import { SlideViewType } from './alertListDetail.types';

export const POPUP_MIN_SIZE = {
  WIDTH: 1200,
  HEIGHT: 700,
};
export const SYSTEM_ALERT_GROUP_ID = '0';
export const USE_SLIDE_VIEW = {
  DASHBOARD: 'dashboard',
  DATABASE: 'database',
  OBJECT_TOPOLOGY: 'objectTopology',
  CLUSTER_TOPOLOGY: 'clusterTopology',
  BUSINESS: 'business',
  NETWORK_OBJECT: 'network_object',
} as const;

/**
 * /src/common/define/apiTrace.define.ts
 * @example 세 가지 필드가 있어야 함.
 *  DASHBOARD_ALERT_DETAIL: {
 *   ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.DASHBOARD_ALERT_DETAIL}/Alert Group and Rules`,
 *   CLEAR_ALERT_RULE: `${MAIN_FRAMES.DASHBOARD_ALERT_DETAIL}/Clear User Alert`,
 *   CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.DASHBOARD_ALERT_DETAIL}/Clear System Alert`,
 *  },
 */
export const FRAME_KEY = {
  [USE_SLIDE_VIEW.DASHBOARD]: 'DASHBOARD_ALERT_DETAIL',
  [USE_SLIDE_VIEW.DATABASE]: 'DATABASE_ALERT_DETAIL',
  [USE_SLIDE_VIEW.OBJECT_TOPOLOGY]: 'K8S_OBJECT_TOPOLOGY_ALERT_DETAIL',
  [USE_SLIDE_VIEW.CLUSTER_TOPOLOGY]: 'K8S_CLUSTER_TOPOLOGY_ALERT_DETAIL',
  [USE_SLIDE_VIEW.BUSINESS]: 'BUSINESS_ALERT_DETAIL',
  [USE_SLIDE_VIEW.NETWORK_OBJECT]: 'NETWORK_OBJECT_ALERT_DETAIL',
} as const;

export const ALERT_API_INFO: Record<
  SlideViewType,
  (...args: any[]) => Parameters<ReturnType<typeof useRtmApi>['callApi']>[0]
> = {
  [USE_SLIDE_VIEW.DASHBOARD]: (params, frameName) => {
    return {
      fn: getGroupAndRuleListAlertCommonControllerAxios,
      frameName,
      params,
      isPolling: true,
      isTimeout: false,
    };
  },
  [USE_SLIDE_VIEW.DATABASE]: (params, frameName) => {
    const { instanceIds, view, dbType } = params;
    const dbFrameName = `${frameName} - ${dbType} ${view}`;
    return {
      fn: getCommonAlertListAlertCommonControllerAxios,
      frameName: dbFrameName,
      params: {
        targetKind: 'database',
        subTargetKinds: ['database'],
        targetIds: instanceIds,
      },
      isPolling: true,
      isTimeout: false,
    };
  },
  [USE_SLIDE_VIEW.OBJECT_TOPOLOGY]: (params, frameName) => {
    return {
      fn: getK8sAlertListAlertCommonControllerAxios,
      frameName,
      params,
      isPolling: true,
      isTimeout: false,
    };
  },
  [USE_SLIDE_VIEW.CLUSTER_TOPOLOGY]: (params, frameName) => {
    return {
      fn: getClusterTopologyAlertListAlertCommonControllerAxios,
      frameName,
      params,
      isPolling: true,
      isTimeout: false,
    };
  },
  [USE_SLIDE_VIEW.BUSINESS]: (params, frameName) => {
    return {
      fn: getCommonAlertListAlertCommonControllerAxios,
      frameName,
      params: {
        ...params,
        targetKind: 'business_service_group',
        subTargetKinds: ['database', 'application'],
      },
      isPolling: true,
      isTimeout: false,
    };
  },
  [USE_SLIDE_VIEW.NETWORK_OBJECT]: (params, frameName) => {
    return {
      fn: getCommonAlertListAlertCommonControllerAxios,
      frameName,
      params: {
        ...params,
        targetKind: 'network_object',
        subTargetKinds: ['network_object'],
      },
      isPolling: true,
      isTimeout: false,
    };
  },
};
